import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { NotificationService } from 'src/app/services/notification.service';
import { SearchService } from 'src/app/services/search.service';
import { SiteService } from 'src/app/services/site.service';
import { UserService } from 'src/app/services/user.service';
import { ButtonRendererComponent } from '../AgGrid/button-renderer/button-renderer.component';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.css']
})
export class IntegrationComponent implements OnInit {

  protected integrationLoaded:boolean = false;
  protected arrays: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected physicalAccessArray: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];
  protected peopleAccessArray: Array<{
    ApplicationType: string
    DisplayValue: string
    ExtApplicationName: string
    ExtApplicationTypeID: number
    IntegrationID: number
    IntegrationName: string
    isLinked?: boolean
    isClicked?: boolean
  }> = [];

  isLinkedUsrPplMgmt: boolean;
  isLinkedPhyAMgmt: boolean;
  protected rowData =  [];
  public showGrid : boolean = false;
  protected valueSelected: boolean = false;
  protected userPhysicalMngmnt:string = "UserPhysicalAccessMgmt";
  protected userPepleMangmnt:string = "UserPeopleMgmt";
  protected plpMgmtBoolean : boolean = false;
  protected phyAccessMgmtBoolean : boolean = false;
  enableUnlink: boolean = false;
  protected selectedValue;
  protected responseStored : any;
  protected filteredPlpMgmtChipList = [];
  protected filteredPhyAccessMgmtChipList = [];
  protected intListMgmt = [];
  protected intListAccMgmt = [];
  selection = new SelectionModel(true);
  list = Array.from({ length: 100 }).map((_, i) => `Item #${i}`);
  selectionPhysical = new SelectionModel(true);
  protected userIntegrationData: Array<{
    IntegrationID: any;
    ExternalAppID: any;
  }> = [];
  protected columnDefs = [];
  protected countPlpMgmt : number = 0;
  protected countPhyAccMgmt : number = 0;
  protected disableIntegrations: boolean = false;
  protected gridOptions: Object;
  @Output() userIntegrationDataChanged: EventEmitter<any> = new EventEmitter();
  @Input('loadedData') public loadedUserData: any;
  protected searchKey: string;

  constructor(
    protected userService : UserService,
    protected siteService : SiteService,
    protected searchService: SearchService,
    protected notificationService: NotificationService
  ) { }

  ngOnInit(): void { 
    this.loadIntegrationsStucture();
    this.disableIntegrations = !this.loadedUserData[0].EnableIntegration;
    //disable the grid if it is true
    if (this.disableIntegrations) {
      this.gridOptions = {
        suppressCellSelection: true
      }
    }
  }

  loadIntegrationsStucture(){
    this.userService.getIntegrations().subscribe((response:any) => {
      response.body.Integrations.forEach(element => {
        this.arrays.push(element);
        this.physicalAccessArray = this.arrays.filter(x => x.DisplayValue == 'UserPhysicalAccessMgmt');
        this.peopleAccessArray = this.arrays.filter(x => x.DisplayValue == 'UserPeopleMgmt');
        this.integrationLoaded = true;
      })
      this.peopleAccessArray.forEach(ele => {
        if (ele.DisplayValue === "UserPeopleMgmt") {
          const reqData = this.loadedUserData[0].IntegrationsDetails;
          reqData.UserInegrations.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              ele.isLinked = true;
              this.isLinkedUsrPplMgmt = true;
              if(!this.intListMgmt.includes(data.UserIntegrationID)){
                this.intListMgmt.push(data.UserIntegrationID);
                this.filteredPlpMgmtChipList.push(data);
                this.userIntegrationData.push({
                  IntegrationID: data.IntegrationID,
                  ExternalAppID: data.ExternalAppID
                })
            }
            }
            this.integrationLoaded = true;
          })
        }
      })
      this.physicalAccessArray.forEach(ele => {
        if (ele.DisplayValue === "UserPhysicalAccessMgmt") {
          const reqData = this.loadedUserData[0].IntegrationsDetails;
          reqData.UserInegrations.forEach(data => {
            if (ele.IntegrationID === data.IntegrationID) {
              ele.isLinked = true;
              this.isLinkedPhyAMgmt = true;

              if(!this.intListAccMgmt.includes(data.UserIntegrationID)){
                this.intListAccMgmt.push(data.UserIntegrationID);
                this.filteredPhyAccessMgmtChipList.push(data);
                this.userIntegrationData.push({
                  IntegrationID: data.IntegrationID,
                  ExternalAppID: data.ExternalAppID
                })
            }
            }
          })
        }
      });
    });
  }

  onSystemIntegratedChange(selection: MatSelectionListChange) {
    this.valueSelected = true;
    this.searchKey = '';
    this.selectedValue = selection.option.value;
    this.showGrid = false;
    this.rowData = [];

    this.clickedIntegrationIs();

      this.userService.getUserIntegrationInfo(this.loadedUserData[0].RequestorUserID, this.selectedValue).subscribe((res: any) => {
        const data = res.body.data;
          if(data.length > 0) {
            data.forEach(ele => {
              if(!this.intListMgmt.includes(ele.UserIntegrationID)){
                  this.filteredPlpMgmtChipList.push(ele);
                  this.intListMgmt.push(ele.UserIntegrationID);
                  this.userIntegrationData.push({
                    IntegrationID: ele.IntegrationID,
                    ExternalAppID: ele.ExternalAppID
                  })
              }
            })
            } 
      })
     this.userIntegrationDataChanged.emit(this.userIntegrationData);
    selection.option.selected
      ? this.selection.select(selection.option.value)
      : this.selection.deselect(selection.option.value);
  }

  onSystemIntegratedChangePhysical(selectionPhysical: MatSelectionListChange) {
    this.valueSelected = true;
    this.searchKey = '';
    this.selectedValue = selectionPhysical.option.value;
    this.showGrid = false;
    this.rowData = [];

    this.clickedIntegrationIs();

      this.userService.getUserIntegrationInfo(this.loadedUserData[0].RequestorUserID, this.selectedValue).subscribe((res: any) => {
        const data = res.body.data;
        if(data.length > 0) {
          data.forEach(ele => {
            if(!this.intListAccMgmt.includes(ele.UserIntegrationID)){
                this.filteredPhyAccessMgmtChipList.push(ele);
                this.intListAccMgmt.push(ele.UserIntegrationID);
                this.userIntegrationData.push({
                  IntegrationID: ele.IntegrationID,
                  ExternalAppID: ele.ExternalAppID
                })
            }
          })
          } 
      })
      this.userIntegrationDataChanged.emit(this.userIntegrationData);
    selectionPhysical.option.selected
      ? this.selectionPhysical.select(selectionPhysical.option.value)
      : this.selectionPhysical.deselect(selectionPhysical.option.value);
  }
  
  // Code for Drag and Drop
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  //to check which integartion is clicked so that to indicate css on that selected integartion
  clickedIntegrationIs() {
    this.physicalAccessArray.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })
    this.peopleAccessArray.forEach(ele => {
      ele.isClicked = false;
      if(ele.IntegrationID == this.selectedValue.IntegrationID) {
        ele.isClicked = true;
      }
    })
  }

  removeIntegration(index: number, user): void {
    if (index >= 0) {
      if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
        this.filteredPlpMgmtChipList.splice(index, 1);
      }
      else {
        this.filteredPhyAccessMgmtChipList.splice(index, 1);
      }
    }
    //const userData = user.split(' | ');
    this.userIntegrationData = this.userIntegrationData.filter(ele => ele.ExternalAppID !== user.ExternalAppID);
    this.userIntegrationDataChanged.emit(this.userIntegrationData);
  }

  addIntegration(e) {
    const data = e.rowData;
    if (this.selectedValue.DisplayValue === this.userPepleMangmnt) {
      const plpData = [];
      plpData.push(data);
      plpData.forEach((element) => {
        if (!this.filteredPlpMgmtChipList.includes(element)) {
          this.filteredPlpMgmtChipList.push(element);
          this.userIntegrationData.push({
            IntegrationID: data.IntegrationID,
            ExternalAppID: data.ExternalAppID
          });
        }
      });
    } else if (this.selectedValue.DisplayValue === this.userPhysicalMngmnt) {
      const phyMgmtData = [];
      phyMgmtData.push(data);
      phyMgmtData.forEach((ele) => {
        if (!this.filteredPhyAccessMgmtChipList.includes(ele)) {
          this.filteredPhyAccessMgmtChipList.push(ele);
          this.userIntegrationData.push({
            IntegrationID: ele.IntegrationID,
            ExternalAppID: ele.ExternalAppID
          });
        }
      });
    }
    this.userIntegrationDataChanged.emit(this.userIntegrationData);
  }

  clearSearchField(){
    this.searchKey = '';
  }
  onSearchBtnClick(event) {
    this.searchService
      .smartSearchMultiSiteAPI(this.searchKey, this.selectedValue.DisplayValue, null,false)
      .then(() => {
        const res = this.searchService.searchRecords;
        this.rowData = res.filter(ele => ele.IntegrationID == this.selectedValue.IntegrationID);
        this.showGrid = true;
        this.setColumnDefs();
        this.searchService.key = '';
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  setColumnDefs() {
    this.columnDefs = [
      {
        headerName: "Link User",
        field: "Link",
        cellStyle: { color: "#589bf8" },
        cellRendererFramework: ButtonRendererComponent,
        width: 100,
        cellRendererParams: {
          onClick: this.addIntegration.bind(this),
          label: "View Assets"
        },
        lockPosition: true,
      },
      {
        headerName: "First Name",
        field: "FirstName"
      },
      {
        headerName: "Last Name",
        field: "LastName"
      },
      {
        headerName:"App ID",
        field: "ExternalAppID"
      }
    ];
  }
}
