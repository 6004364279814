import { Injectable, Output, EventEmitter } from "@angular/core";
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { UserService } from "./user.service";
import { NotificationService } from "./notification.service";
import { environment } from "src/environments/environment";
import { Site, SiteService } from "./site.service";

@Injectable({
  providedIn: "root",
})
export class LimitRequestService {
  protected limitRequest: {
    OrgCombinedLimitRequestID: number;
    ApproverArray: Array<{
      ApprovalRequestID: number;
      RequesterComments: string;
      ApprovalComments: string;
      ApprovedBy: string;
      ApprovalGroupRequestID: number;
      ApprovalGroupID: number;
      Position: number;
      ApprovalGroupRequestReason: string;
      Approved: number;
      ApproverGroupName: string;
      ApprovedDate: string;
      ApprovedByActive: number;
    }>;
    RequestedBy: string;
    RequestedDate: string;
    OrganizationID: number;
    OrganizationName: string;
    StartDate: string;
    EndDate: string;
    EntryPointGroupID: number;
    EntryPointGroupName: string;
    ParkingLotID: number;
    ParkingLotName: string;
    ExtraLimit: number;
  };

  private limitApproval: Array<{
    ApprovalRequestID: number;
    ApprovalGroupRequestID: number;
    RequestedBy: string;
    RequestedOn: string;
    Status: string;
    Organization: string;
    StartDate: string;
    EndDate: string;
    ParkingLot: string;
    EntryPointGroup: string;
    IncreaseByLimit: number;
    ApproverComments: string;
    RequesterComments: string;
    AffectedEntityDetails: Array<{
      AffectedEntity: string;
      TargetCapacity: number;
      ApprovedSummary: number;
      RequestedSummary: number;
      series: Array<{
        date: string;
        ApprovedCapacity: number;
        RequestedCapacity: number;
      }>;
    }>;
  }> = [];

  private passApproval: {
    ApprovalRequestID: number;
    ApprovalGroupRequestID: number;
    RequestedBy: string;
    RequestedOn: string;
    Status: string;
    Organization: string;
    StartTime: string;
    EndTime: string;
    ApproverComments: string;
  };

  @Output() requestUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  public message: string;
  public parkingRequestOpen = false;
  public acessRequestOpen =false
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private notificationService: NotificationService,
    private siteService: SiteService
  ) {}

  // call API to get the details for a particular limit request
  public getLimitRequestByIDAPI(
    orgCombinedLimitRequestID: number
  ): Promise<any> {
    const apiURL = environment.getAPI("getCombinedLimitRequest");

    const body = {
      OrgCombinedLimitRequestID: orgCombinedLimitRequestID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.limitRequest = success.body[0];

            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // get the details for a particular limit request
  public getLimitRequestByID(orgCombinedLimitRequestID: number) {
    return { ...this.limitRequest };
  }

  // raise limit request api call
  public createLimitRequestAPI(
    organizationID: number,
    startDate: string,
    endDate: string,
    increaseLimitBy: number,
    entryPointGroupID: number,
    parkingLotID: number,
    reason: string
  ): Promise<any> {
    const apiURL = environment.getAPI("combinedLimitRequestCreate");
    const body = {
      OrganizationID: organizationID,
      StartDate: startDate,
      EndDate: endDate,
      NumVisitors: increaseLimitBy,
      EntryPointGroupID: entryPointGroupID,
      ParkingLotID: parkingLotID,
      Reason: reason,
      UserID: this.userService.userID,
      Status: "Requested",
      UpdaterID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.notificationService.success(this.message);
            this.requestUpdated.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // cancel limit request api call
  public cancelOrgLimitRequestAPI(OrgCombinedLimitRequestID: number){

    const apiURL = environment.getAPI("cancelOrgLimitRequest");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      UpdaterID: this.userService.userID,
      OrgCombinedLimitRequestID: OrgCombinedLimitRequestID,
      SiteID: this.siteService.selectedSiteID 
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    res.subscribe((data: HttpResponse<any>) => {
      this.message = data.body.Message;
      this.notificationService.success(this.message);
      this.requestUpdated.emit(true);
    },
    (error: HttpErrorResponse) => {
      this.notificationService.failure(error.message);
    })
    return res;
  }

  // get Restricted Person approval details
  public getPassApprovalDetailsByIDAPI(passID: number): Promise<any> {
    const apiURL = environment.getAPI("getPOIApprovalRequest");

    const body = {
      PassID: passID,
      UserID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.passApproval = success.body[0];
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getPassApprovalDetailsByID() {
    return this.passApproval;
  }

  // get approval details
  public getApprovalDetailsByIDAPI(
    orgCombinedLimitRequestID: number
  ): Promise<any> {
    const apiURL = environment.getAPI("getApprovalRequest");
    
    const body = {
      OrgCombinedLimitRequestID: orgCombinedLimitRequestID,
      userID: this.userService.userID,
      SiteID: this.siteService.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.limitApproval = success.body;
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getApprovalDetailsByID() {
    return [...this.limitApproval];
  }

  // invoked to approve a limit request
  public async approveLimitRequestAPI(
    orgCombinedLimitRequestID: number,
    approvalGroupRequestID: number,
    approversReason?: string
  ) {
    await this.getApprovalDetailsByIDAPI(orgCombinedLimitRequestID);
    const apiURL = environment.getAPI("combinedLimitRequestUpdate");

    const body = {
      OrgCombinedLimitRequestID: orgCombinedLimitRequestID,
      Status: "Approved",
      Reason: approversReason === undefined ? null : approversReason,
      UserID: this.userService.userID,
      // ApprovalGroupRequestID: this.getApprovalDetailsByID()[0].ApprovalGroupRequestID,
      ApprovalGroupRequestID: approvalGroupRequestID,
      UpdaterID: this.userService.userID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.requestUpdated.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoked to reject a limit request
  public async rejectLimitRequestAPI(
    orgCombinedLimitRequestID: number,
    approvalGroupRequestID: number,
    approversReason?: string
  ) {
    await this.getApprovalDetailsByIDAPI(orgCombinedLimitRequestID);
    const apiURL = environment.getAPI("combinedLimitRequestUpdate");

    const body = {
      OrgCombinedLimitRequestID: orgCombinedLimitRequestID,
      Status: "Rejected",
      Reason: approversReason === undefined ? null : approversReason,
      UserID: this.userService.userID,
      // ApprovalGroupRequestID: this.getApprovalDetailsByID()[0].ApprovalRequestID,
      ApprovalGroupRequestID: approvalGroupRequestID,
      UpdaterID: this.userService.userID
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.requestUpdated.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoked to approve a pass request
  public async approvePassRequestAPI(passID: number, approvalGroupRequestID: number, approvalType: number, approversReason?: string) {
    // await this.getPassApprovalDetailsByIDAPI(passID);
    const apiURL = environment.getAPI("POIApprovalStatusUpdate");

    const body = {
      Status: "Approved",
      Comments: approversReason === undefined ? null : approversReason,
      UserID: this.userService.userID,
      PassID: passID,
      ApprovalGroupRequestID: approvalGroupRequestID,
      UpdaterID: this.userService.userID,
      ApprovalType: approvalType,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.message = success.body.Message;
            this.requestUpdated.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  // invoked to reject a pass request
  public async rejectPassRequestAPI(passID: number,  approvalGroupRequestID: number, approvalType: number, approversReason: string) {
    // await this.getPassApprovalDetailsByIDAPI(passID);
    const apiURL = environment.getAPI("POIApprovalStatusUpdate");

    const body = {
      Status: "Rejected",
      Comments: approversReason === undefined ? null : approversReason,
      UserID: this.userService.userID,
      PassID: passID,
      ApprovalGroupRequestID: approvalGroupRequestID,
      UpdaterID: this.userService.userID,
      ApprovalType: approvalType,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          (success: HttpResponse<any>) => {
            this.message = success.body.Message;
            this.requestUpdated.emit(true);
            resolve(success);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public getLimitRequestDetailsAPI(orgCombinedLimitRequestID: number){

    const apiURL = environment.getAPI("getLimitRequestDetails");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      OrgCombinedLimitRequestID: orgCombinedLimitRequestID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }

  public getPOIMatchesByPassIDAPI(passID: number){

    const apiURL = environment.getAPI("getPOIMatchesByPassID");
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };
    const body = {
      PassID: passID,
    }
    const res = this.http.post<any>(apiURL, body, httpOptions);
    return res;
  }
}
