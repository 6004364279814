import { DashBoardMobileComponent } from "./dashboard/mobile/dashboard.component.mobile";
import { DashBoardDesktopComponent } from "./dashboard/desktop/dashboard.component.desktop";
import { ViewSelectorService } from "./services/view-selector.service";
import { NgModule } from "@angular/core";
import {
  Routes,
  Router,
  RouterModule,
  PreloadAllModules,
} from "@angular/router";
import { LoginRegistrationComponentDesktop } from "./login-registration/desktop/login-registration.component.desktop";
import { LoginRegistrationComponentMobile } from "./login-registration/mobile/login-registration.component.mobile";
import { MasterLayoutDesktopComponent } from "./master-layout/desktop/master-layout.desktop.component";
import { MasterLayoutMobileComponent } from "./master-layout/mobile/master-layout.mobile.component";
import { MasterDataComponentDesktop } from "./master-data/desktop/master-data.component.desktop";
import { MasterDataComponentMobile } from "./master-data/mobile/master-data.component.mobile";
import { SecurityComponentDesktop } from "./security/desktop/security.component.desktop";
import { SecurityComponentMobile } from "./security/mobile/security.component.mobile";
import { BuildingDesktopComponent } from "./building/desktop/building.desktop.component";
import { BuildingMobileComponent } from "./building/mobile/building.mobile.component";
import { CallerAuthenticationComponentDesktop } from "./caller-authentication/desktop/caller-authentication.component.desktop";
import { CallerAuthenticationComponentMobile } from "./caller-authentication/mobile/caller-authentication.component.mobile";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { MyRequestsComponent } from "./my-requests/my-requests.component";
import { MyApprovalsComponent } from "./my-approvals/my-approvals.component";
import { CapacityManagementComponent } from "./capacity-management/capacity-management.component";
import { PersonOfInterestComponent } from "./person-of-interest/person-of-interest.component";

import {
  OktaCallbackComponent,
  OktaAuthGuard,
  OktaLoginRedirectComponent,
} from "@okta/okta-angular";
import { LoggedInUserGuard } from "./guards/loggedInUserGuard";
import { UserRegisteredGuard } from "./guards/userRegisteredGuard";
import { VerifyUserComponent } from "./verify-user/verify-user.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import { ViewControlGuard } from "./guards/viewControlGuard";
import { PassTransactionComponent } from "./pass-transaction/pass-transaction.component";
import { TeamComponent } from "./teams/team/team-modal.component";
// import { OrgMasterDataComponentDesktop } from "./organization-master-data/desktop/org-master-data.component.desktop";
import { PermissionsComponent } from "./permissions/permissions.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TemplateComponent } from './templates/template/template-modal.component';
import { OrgSecurityComponent } from './organization-security/org-security.component';
import { OrgMasterDataComponent } from "./organization-master-data/org-master-data.component";
import { WIPSiteComponent } from "./common/wipsite/wipsite.component";
import { UserVerificationComponent } from "./user-verification/user-verification.component";
import { UserAccessRequestComponent } from "./user-access-request/user-access-request.component";

const desktopRoutes: Routes = [
  {
    path: "",
    component: LoginRegistrationComponentDesktop,
    pathMatch: "full",
    canActivate: [LoggedInUserGuard],
  },
  {
    path: "login",
    component: OktaLoginRedirectComponent,
    canActivate: [LoggedInUserGuard],
  },
  {
    path: "implicit/callback",
    component: OktaCallbackComponent,
  },
  {
    path: "verify-user",
    component: VerifyUserComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: "user-verification",
    component: UserVerificationComponent,
  },
  {
    path: "user-access-request",
    component: UserAccessRequestComponent,
  },
  {
    path: "site-maintenance",
    component: WIPSiteComponent
  },
  {
    path: "",
    component: MasterLayoutDesktopComponent,
    children: [
      {
        path: "dashboard",
        component: DashBoardDesktopComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },

      {
        path: "master-data",
        component: MasterDataComponentDesktop,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "org-master-data",
        component: OrgMasterDataComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      {
        path: "org-security",
        component: OrgSecurityComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      {
        path: "security",
        component: SecurityComponentDesktop,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "permissions",
        component: PermissionsComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "call-in-pass",
        component: CallerAuthenticationComponentDesktop,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },

      {
        path: "template",
        component: TemplateComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      {
        path: "profile",
        component: UserProfileComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      {
        path: "my-requests",
        component: MyRequestsComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "my-approvals",
        component: MyApprovalsComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "capacity-management",
        component: CapacityManagementComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      {
        path: "person-of-interest",
        component: PersonOfInterestComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "audit",
        component: AuditLogComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard, ViewControlGuard],
      },
      {
        path: "pass-transactions",
        component: PassTransactionComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
    ],
  },
];

const mobileRoutes: Routes = [
  {
    path: "site-maintenance",
    component: WIPSiteComponent
  },
  {
    path: "",
    component: LoginRegistrationComponentMobile,
    pathMatch: "full",
    canActivate: [LoggedInUserGuard],
  },
  {
    path: "login",
    component: OktaLoginRedirectComponent,
    canActivate: [LoggedInUserGuard],
  },
  {
    path: "implicit/callback",
    component: OktaCallbackComponent,
  },
  {
    path: "verify-user",
    component: VerifyUserComponent,
    canActivate: [OktaAuthGuard],
  },
  {
    path: "user-verification",
    component: UserVerificationComponent,
  },
  {
    path: "user-access-request",
    component: UserAccessRequestComponent,
  },
  {
    path: "site-wip",
    component: WIPSiteComponent
  },
  {
    path: "",
    component: MasterLayoutMobileComponent,
    children: [
      {
        path: "dashboard",
        component: DashBoardMobileComponent,
        canActivate: [OktaAuthGuard, UserRegisteredGuard],
      },
      { path: "login", component: OktaLoginRedirectComponent },
      { path: "master-data", component: MasterDataComponentMobile },
      { path: "team", component: OrgSecurityComponent },
      { path: "security", component: SecurityComponentMobile },
      { path: "master-data/buildings", component: BuildingMobileComponent },
      { path: "call-in-pass", component: CallerAuthenticationComponentMobile },
      { path: "template", component: TemplatesComponent },
      { path: "profile", component: UserProfileComponent },
      { path: "my-requests", component: MyRequestsComponent },
      { path: "my-approvals", component: MyApprovalsComponent },
      { path: "capacity-management", component: CapacityManagementComponent },
      { path: "person-of-interest", component: PersonOfInterestComponent },
      { path: "implicit/callback", component: OktaCallbackComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(desktopRoutes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(router: Router, service: ViewSelectorService) {
    if (service.getIsMobile()) {
      router.resetConfig(mobileRoutes);
    }
  }
}
