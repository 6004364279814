import { Component, OnInit } from "@angular/core";
import { MatDialogConfig, MatDialog } from "@angular/material";

import { MasterLayoutComponent } from "../master-layout.component";
import { ViewSelectorService } from "../../services/view-selector.service";
import { NewpassModalComponentMobile } from "src/app/newpass-modal/mobile/newpass-modal.component.mobile";
import { NewpassModalComponentDesktop } from "src/app/newpass-modal/desktop/newpass-modal.component.desktop";
import { SpinnerService } from "../../services/spinner.service";
import { AuthService } from "src/app/services/auth.service";
import { UserService } from "src/app/services/user.service";
import { CreateTemplateComponent } from 'src/app/templates/template/create-template-modal/create-template-modal.component';
import { PassService } from "src/app/services/pass.service";
import { NotificationService } from "src/app/services/notification.service";
import { SiteService } from "src/app/services/site.service";
import { HttpErrorResponse } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-master-layout-desktop",
  templateUrl: "./master-layout.desktop.component.html",
  styleUrls: ["./master-layout.desktop.component.css"],
})
export class MasterLayoutDesktopComponent extends MasterLayoutComponent {
  constructor(
    viewSelectorService: ViewSelectorService,
    dialog: MatDialog,
    spinnerService: SpinnerService,
    authService: AuthService,
    userService: UserService,
    siteService: SiteService,
    passService: PassService,
    notificationService: NotificationService,
    messageService: MessageService
  ) {
    super(
      viewSelectorService,
      dialog,
      spinnerService,
      authService,
      userService,
      siteService,
      passService,
      notificationService,
      messageService
    );
  }

  // opens new pass modal
  createPass() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = "backdropBackground";
    dialogConfig.disableClose = true;
    dialogConfig.minWidth= '1200px';
    dialogConfig.maxWidth= '2400px';
    dialogConfig.width= '80%';
    const dialogConfigMobile = new MatDialogConfig();
    dialogConfigMobile.autoFocus = true;
    dialogConfigMobile.backdropClass = "backdropBackground";
    dialogConfigMobile.disableClose = true;
    if (this.passService.newPassOpen) return;
    if (this.isMobile === true) {
      this.dialog.open(NewpassModalComponentMobile, dialogConfigMobile);
      this.passService.newPassOpen = true;
    } else {
      this.dialog.open(NewpassModalComponentDesktop, dialogConfig);
      this.passService.newPassOpen = true;
    }

  }


}