import { ViewpassModalComponentDesktop } from "./viewpass-modal/desktop/viewpass-modal.component.desktop";
import { EditPassModalComponentDesktop } from "./edit-pass-modal/desktop/edit-pass-modal.component.desktop";
import { DuplicatePassModalComponentDesktop } from "./duplicate-pass-modal/desktop/duplicate-pass-modal.component.desktop";
import { NewpassModalComponentMobile } from "./newpass-modal/mobile/newpass-modal.component.mobile";
import { DashboardWidgetsComponentMobile } from "./dashboard-widgets/mobile/dashboard-widgets.component.mobile";
import { NavBarMobileComponent } from "./nav-bar/mobile/nav-bar.comonent.mobile";
import { DashBoardMobileComponent } from "./dashboard/mobile/dashboard.component.mobile";
import { DashBoardDesktopComponent } from "./dashboard/desktop/dashboard.component.desktop";
import { LoginRegistrationComponentMobile } from "./login-registration/mobile/login-registration.component.mobile";
import { MasterDataComponentDesktop } from "./master-data/desktop/master-data.component.desktop";
import { MasterDataComponentMobile } from "./master-data/mobile/master-data.component.mobile";
import { ViewSelectorService } from "./services/view-selector.service";
import { BrowserModule, Title } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { SecurityComponentDesktop } from "./security/desktop/security.component.desktop";
import { SecurityComponentMobile } from "./security/mobile/security.component.mobile";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import {
  MatDialogModule,
  MatTooltipModule,
  MatChipsModule,
  MatSlideToggleModule,
  MatCardModule,
  MatListModule,
  MatStepperModule,
} from "@angular/material";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatNativeDateModule } from "@angular/material";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatTabsModule } from "@angular/material/tabs";
import {MatGridListModule} from '@angular/material/grid-list';
import { NgMaterialMultilevelMenuModule } from "ng-material-multilevel-menu";

import { AgGridModule } from "ag-grid-angular";
import { AgGridResizeDirective } from "./ag-grid-resize.directive";
import { AvatarModule } from "ngx-avatar";

import { NgxChartsModule } from "@swimlane/ngx-charts";
import {
  IgxTimePickerModule,
  IgxInputGroupModule,
  IgxIconModule,
  IgxButtonModule,
  IgxCalendarModule,
  IgxDialogModule,
} from "igniteui-angular";
import { SpecialCharacterDirective } from "./specialCharacter.directive";
import { PhonenumberMaskDirective } from "./phonenumber-mask.directive";
import { LoginRegistrationComponentDesktop } from "./login-registration/desktop/login-registration.component.desktop";
import { NavBarDesktopComponent } from "./nav-bar/desktop/nav-bar.comonent.desktop";
import { DashboardWidgetsComponentDesktop } from "./dashboard-widgets/desktop/dashboard-widgets.component.desktop";
import { NewpassModalComponentDesktop } from "./newpass-modal/desktop/newpass-modal.component.desktop";
import { ViewpassModalComponentMobile } from "./viewpass-modal/mobile/viewpass-modal.component.mobile";
import { EditPassModalComponentMobile } from "./edit-pass-modal/mobile/edit-pass-modal.component.mobile";
import { DuplicatePassModalComponentMobile } from "./duplicate-pass-modal/mobile/duplicate-pass-modal.component.mobile";
import { CallerAuthenticationComponentDesktop } from "./caller-authentication/desktop/caller-authentication.component.desktop";
import { CallerAuthenticationComponentMobile } from "./caller-authentication/mobile/caller-authentication.component.mobile";

import { AuthService } from "./services/auth.service";
import { MasterLayoutDesktopComponent } from "./master-layout/desktop/master-layout.desktop.component";
import { MasterLayoutMobileComponent } from "./master-layout/mobile/master-layout.mobile.component";

import { BuildingDesktopComponent } from "./building/desktop/building.desktop.component";
import { BuildingMobileComponent } from "./building/mobile/building.mobile.component";
import { ViewBuildingModalComponent } from "./building/modals/view-building-modal/view-building-modal.component";
import { EntryPointComponent } from "./entry-point/entry-point.component";
import { ViewEntryPointModalComponent } from "./entry-point/modals/view-entry-point/view-entry-point-modal.component";
import { UpdateEntryPointComponent } from "./entry-point/modals/update-entry-point/update-entry-point.component";
import { CreateEntryPointComponent } from "./entry-point/modals/create-entry-point/create-entry-point.component";
import { MasterDataComponent } from "./master-data/master-data.component";
import { EntryPointGroupComponent } from "./entry-point-group/entry-point-group.component";
import { CreateEntryPointGroupModalComponent } from "./entry-point-group/modals/create-entry-point-group/create-entry-point-group-modal.component";
import { UpdateEntryPointGroupComponent } from "./entry-point-group/modals/update-entry-point-group/update-entry-point-group.component";
import { ViewEntryPointGroupComponent } from "./entry-point-group/modals/view-entry-point-group/view-entry-point-group.component";
import { EntryPointZoneComponent } from "./entry-point-zone/entry-point-zone.component";
import { CreateEntryPointZoneComponent } from "./entry-point-zone/modals/create-entry-point-zone/create-entry-point-zone-modal.component";
import { UpdateEntryPointZoneComponent } from "./entry-point-zone/modals/update-entry-point-zone/update-entry-point-zone.component";
import { ViewEntryPointZoneComponent } from "./entry-point-zone/modals/view-entry-point-zone/view-entry-point-zone.component";
import { SecurityComponent } from "./security/security.component";
import { RoleMapComponent } from "./role-map/role-map.component";
import { RoleMapModalComponent } from "./role-map/modals/role-map-modal/role-map-modal.component";
import { CallerAuthenticationComponent } from "./caller-authentication/caller-authentication.component";
import { CommonConfirmDialogComponent } from "./common-confirm-dialog/common-confirm-dialog.component";
import { SearchFilterModalComponent } from "./nav-bar/modals/search-filter.modal.component";
import { ParkingLotComponent } from "./parking-lot/parking-lot.component";
import { ParkingLotGroupComponent } from "./parking-lot-group/parking-lot-group.component";
import { CreateParkingModalComponent } from "./parking-lot/modals/create-parking/create-parking-modal.component";
import { ViewParkingModalComponent } from "./parking-lot/modals/view-parking/view-parking-modal.component";
import { UpdateParkingModalComponent } from "./parking-lot/modals/update-parking/update-parking-modal.component";
import { UsersComponent } from "./users/users.component";
import { AssignUpdateRoleModalComponent } from "./users/modals/assign-update-role/assign-update-role-modal.component";

import { ViewParkingGroupModalComponent } from "./parking-lot-group/modals/view-parking-group/view-parking-group-modal.component";
import { CreateParkingGroupModalComponent } from "./parking-lot-group/modals/create-parking-group/create-parking-group-modal.component";
import { UpdateParkingGroupModalComponent } from "./parking-lot-group/modals/update-parking-group/update-parking-group-modal.component";
import { OrganizationComponent } from "./organization/organization.component";
import { ViewOrganizationModalComponent } from "./organization/modals/view-organization-modal/view-organization-modal.component";
import { RoleMapUpdateModalComponent } from "./role-map/modals/update-role-mapping-modal/role-map-update-modal.component";
import { RoleMapViewModalComponent } from "./role-map/modals/view-role-mapping-modal/view-role-map-modal.component";
import { UpdateOrganizationModalComponent } from "./organization/modals/update-organization-modal/update-organization-modal.component";
import { ViewUserModalComponent } from "./users/modals/view-user/view-user-modal.component";
import { CreateUserModalComponent } from "./users/modals/create-user/create-user-modal.component";
import { CommonAlertDialogComponent } from "./common-alert-dialog/common-alert-dialog.component";
import { GridsterModule } from "angular-gridster2";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { WidgetSelectionModalComponent } from "./dashboard-widgets/modals/widget-selection.modal.component";
import { CreateBadgeHolderPassModalComponent } from "./create-badge-holder-pass/create-badge-holder-pass-modal.component";

import { NgxPrintModule } from "ngx-print";
import { MyRequestsComponent } from "./my-requests/my-requests.component";
import { MyApprovalsComponent } from "./my-approvals/my-approvals.component";
import { LimitRequestsComponent } from "./my-requests/limit-requests/limit-requests.component";
// import { EntryPointZoneRequestsComponent } from "./my-requests/entry-point-zone-requests/entry-point-zone-requests.component";
import { CapacityManagementComponent } from "./capacity-management/capacity-management.component";
import { RaiseCapacityModalComponent } from "./capacity-management/modals/raise-capacity-request-modal/raise-capacity-request-modal.component";
import { ViewParkingLimitRequestModalComponent } from "./my-requests/modals/view-parking-limit-request-modal/view-parking-limit-request-modal.component";
import { LimitRequestApprovalsComponent } from "./my-approvals/limit-requests-approvals/limit-requests-approvals.component";
import { ViewLimitApprovalModalComponent } from "./my-approvals/modals/view-limit-approval-modal/view-limit-approval-modal.component";
import { QRCodeModule } from "angularx-qrcode";
import { RejectApproveShortModalComponent } from "./my-approvals/modals/reject-approve-short-modal/reject-approve-short-modal.component";
import { CreateLimitRequestModalComponent } from "./my-requests/modals/create-limit-request-modal/create-limit-request-modal.component";
import { PersonOfInterestComponent } from "./person-of-interest/person-of-interest.component";
import { ViewAuthenticateCallerModalComponent } from "./caller-authentication/modals/view-authenticate-caller/view-authenticate-caller-modal.component";
import { PassApprovalsComponent } from "./my-approvals/pass-approvals/pass-approvals.component";
import { viewPassApprovalModalComponent } from "./my-approvals/modals/view-pass-approval-modal/view-pass-approval-modal.component";
import { RejectApprovePassModalComponent } from "./my-approvals/modals/reject-approve-pass-modal/reject-approve-pass-modal.component";

import { OKTA_CONFIG, OktaAuthModule } from "@okta/okta-angular";
import { environment } from "../environments/environment";
import { LoggedInUserGuard } from "./guards/loggedInUserGuard";
import { UserRegisteredGuard } from "./guards/userRegisteredGuard";
import { VerifyUserComponent } from "./verify-user/verify-user.component";
import { MinDirective } from "./min.directive";
import { MaxDirective } from "./max.directive";
import { MasterDataToolTipComponent } from "./common/master-data-tool-tip/master-data-tool-tip.component";
import { AuditLogComponent } from "./audit-log/audit-log.component";
import { PassAuditComponent } from "./pass-audit/pass-audit.component";
import { ViewPassAuditModalComponent } from "./pass-audit/modals/view-pass-audit-modal/view-pass-audit-modal.component";
import { ViewControlGuard } from "./guards/viewControlGuard";
import { CountdownModule } from "ngx-countdown";
import { PassTransactionComponent } from "./pass-transaction/pass-transaction.component";
import { ViewPassTransactionModalComponent } from "./pass-transaction/modals/view-pass-transaction-modal/view-pass-transaction-modal.component";
import { TemplatesComponent } from "./templates/templates.component";
import { TemplateComponent } from "./templates/template/template-modal.component";
import { TemplateCategoryComponent } from "./templates/template-category/template-category-modal.component";
import { CreateTemplateCategoryComponent } from "./templates/template-category/create-template-category-modal/create-template-category-modal.component";
import { UpdateTemplateCategoryComponent } from "./templates/template-category/edit-template-category-modal/edit-template-category-modal.component";
import { CreateTemplateComponent } from "./templates/template/create-template-modal/create-template-modal.component";
import { UpdateTemplateComponent } from "./templates/template/edit-template-modal/edit-template-modal.component";
import { ViewTemplateComponent } from "./templates/template/view-template-modal/view-template-modal.component";
import { ViewTemplateCategoryComponent } from "./templates/template-category/view-template-category-modal/view-template-category-modal.component";
import { TeamComponent } from "./teams/team/team-modal.component";
import { CreateTeamComponent } from "./teams/team/create-team-modal/create-team-modal.component";
import { UpdateTeamComponent } from "./teams/team/edit-team-modal/edit-team-modal.component";
import { ViewTeamComponent } from "./teams/team/view-team-modal/view-team-modal.component";
import { UserTeamComponent } from "./teams/user-team/user-team-modal.component";
import { OrgMasterDataComponent } from "./organization-master-data/org-master-data.component";
import { OrgSecurityComponent } from "./organization-security/org-security.component";
import { AssignUpdateTeamModalComponent } from "./teams/user-team/assign-update-team/assign-update-team-modal.component";
import { StartdateConfigModalComponent } from "./templates/template/startdate-config/startdate-config.modal.component";
import { WidgetConfigModalComponent } from "./dashboard-widgets/modals/widget-config/widget-config.modal.component";
import {WebcamModule} from 'ngx-webcam';
import { InviteNewUserModal } from "./teams/user-team/invite-new-user-modal/invite-new-user-modal.component";
import { MatChipAutoCompleteComponent } from "./common/mat-chip-auto-complete/mat-chip-auto-complete.component";
import { UserMatChipAutoCompleteComponent } from "./common/user-mat-chip-auto-complete/user-mat-chip-auto-complete.component";
import { SiteSettingsComponent } from "./site-settings/site-settings.component";
import { RoleAssignmentApprovalComponent } from "./my-approvals/role-assignment-approval/role-assignment-approval.component";
import { ViewUserInvitationApprovalModal } from "./my-approvals/modals/view-user-invitation-approval-modal/view-user-invitation-approval-modal.component";
import { ApproveRejectCellRendererComponent } from "./common/AgGrid/approve-reject-cell-renderer/approve-reject-cell-renderer.component";
import { MatDatePickerAdapter } from "./common/mat-date-picker-formatter/mat-date-picker-formatter";
import { PermissionsComponent } from "./permissions/permissions.component";
import { WebcamComponent } from './webcam/webcam.component';
import { ApprovalGroupComponent } from "./approval-group/approval-group.component";
import { CreateApprovalGroupComponent } from "./approval-group/modals/create-approval-group/create-approval-group.component";
import { UpdateApprovalGroupComponent } from "./approval-group/modals/update-approval-group/update-approval-group.component";
import { ViewApprovalGroupComponent } from "./approval-group/modals/view-approval-group/view-approval-group.component";
import { ApprovalRequestConfigurationComponent } from "./approval-request-configuration/approval-request-configuration.component";
import { CreateApprovalRequestConfigurationModalComponent } from "./approval-request-configuration/modals/create-approval-request-configuration-modal/create-approval-request-configuration-modal.component";
import { UpdateApprovalRequestConfigurationModalComponent } from "./approval-request-configuration/modals/update-approval-request-configuration-modal/update-approval-request-configuration-modal.component";
import { ViewApprovalRequestConfigurationModalComponent } from "./approval-request-configuration/modals/view-approval-request-configuration-modal/view-approval-request-configuration-modal.component";
import { ApprovalTypeComponent } from "./approval-type/approval-type.component";
import { UpdateApprovalTypeModalComponent } from "./approval-type/modals/update-approval-type-modal/update-approval-type-modal.component";
import { ViewApprovalTypeModalComponent } from "./approval-type/modals/view-approval-type-modal/view-approval-type-modal.component";
import { PassOnCreateApprovals } from "./my-approvals/pass-on-create-approvals/pass-on-create-approvals.component";
import { ApprovalStatusGridComponent } from "./common/approval-status-grid/approval-status-grid.component";
import { ErrorHandlerService } from "./services/error-handler.service";
import { TokenInterceptor } from "./interceptors/token.intercept";
import { WIPSiteComponent } from './common/wipsite/wipsite.component';
import { CreateOrganizationModalComponent } from './organization/modals/create-organization-modal/create-organization-modal.component';
import { ApprovalMemberComponent } from './approval-member/approval-member.component';
import { CreateApprovalMemberComponent } from "./approval-member/modals/create-approval-member/create-approval-member.component";
import { HolidayCalendarComponent } from "./holiday-calendar/holiday-calendar.component";
import { SessionInterceptor } from "./interceptors/session.interceptor";
import { UserVerificationComponent } from './user-verification/user-verification.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { CustomSnackbarComponent } from './snack-bar/custom-snackbar/custom-snackbar.component';
import { NotificationSettingsComponent } from './notification-settings/notification-settings.component';
import { BasicSnackbarComponent } from "./snack-bar/basic-snackbar/basic-snackbar.component";
import { ButtonRendererComponent } from "./common/AgGrid/button-renderer/button-renderer.component";
import { AccessRequestComponent } from './my-requests/access-request/access-request/access-request.component';
import { CreateAccessRequestComponent } from './my-requests/modals/create-access-request/create-access-request.component';
import { DeactivationDialogComponent } from './deactivation-dialog/deactivation-dialog.component';
import { UserAccessRequestComponent } from './user-access-request/user-access-request.component';
import { ViewAccessRequestModalComponent } from './my-requests/modals/view-access-request-modal/view-access-request-modal.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DomainComponent } from './domain/domain.component';
import { CreateDomainComponent } from './domain/modals/create-domain/create-domain.component';
import { EditDomainComponent } from './domain/modals/edit-domain/edit-domain.component';
import { ViewDomainComponent } from './domain/modals/view-domain/view-domain.component';
import { ViewPassOnCreateApprovalComponent } from './my-approvals/modals/view-pass-on-create-approval/view-pass-on-create-approval.component';
import { IntegrationComponent } from './common/integration/integration.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';


@NgModule({
  declarations: [
    AppComponent,
    NavBarMobileComponent,
    NavBarDesktopComponent,
    LoginRegistrationComponentDesktop,
    LoginRegistrationComponentMobile,
    NewpassModalComponentDesktop,
    NewpassModalComponentMobile,
    DashBoardDesktopComponent,
    DashBoardMobileComponent,
    DashboardWidgetsComponentMobile,
    DashboardWidgetsComponentDesktop,
    ViewpassModalComponentDesktop,
    ViewpassModalComponentMobile,
    EditPassModalComponentDesktop,
    EditPassModalComponentMobile,
    DuplicatePassModalComponentMobile,
    DuplicatePassModalComponentDesktop,
    PhonenumberMaskDirective,
    SpecialCharacterDirective,
    MasterLayoutDesktopComponent,
    MasterLayoutMobileComponent,
    BuildingMobileComponent,
    BuildingDesktopComponent,
    ViewBuildingModalComponent,
    EntryPointComponent,
    ViewEntryPointModalComponent,
    MasterDataComponentDesktop,
    MasterDataComponentMobile,
    ViewEntryPointGroupComponent,
    UpdateEntryPointComponent,
    CreateEntryPointComponent,
    MasterDataComponent,
    EntryPointGroupComponent,
    CreateEntryPointGroupModalComponent,
    UpdateEntryPointGroupComponent,
    EntryPointZoneComponent,
    CreateEntryPointZoneComponent,
    UpdateEntryPointZoneComponent,
    ViewEntryPointZoneComponent,
    SecurityComponent,
    RoleMapComponent,
    RoleMapModalComponent,
    SecurityComponentDesktop,
    SecurityComponentMobile,
    AgGridResizeDirective,
    CallerAuthenticationComponent,
    CallerAuthenticationComponentDesktop,
    CallerAuthenticationComponentMobile,
    CommonConfirmDialogComponent,
    CommonAlertDialogComponent,
    SearchFilterModalComponent,
    ParkingLotComponent,
    ParkingLotGroupComponent,
    CreateParkingModalComponent,
    ViewParkingModalComponent,
    UpdateParkingModalComponent,
    UsersComponent,
    AssignUpdateRoleModalComponent,
    ViewParkingGroupModalComponent,
    CreateParkingGroupModalComponent,
    UpdateParkingGroupModalComponent,
    OrganizationComponent,
    ViewOrganizationModalComponent,
    RoleMapUpdateModalComponent,
    RoleMapViewModalComponent,
    UpdateOrganizationModalComponent,
    ViewUserModalComponent,
    CreateUserModalComponent,
    UserProfileComponent,
    WidgetSelectionModalComponent,
    CreateBadgeHolderPassModalComponent,
    MyRequestsComponent,
    MyApprovalsComponent,
    PersonOfInterestComponent,
    LimitRequestApprovalsComponent,
    PassApprovalsComponent,
    LimitRequestsComponent,
    // EntryPointZoneRequestsComponent,
    CapacityManagementComponent,
    RaiseCapacityModalComponent,
    ViewParkingLimitRequestModalComponent,
    ViewLimitApprovalModalComponent,
    RejectApproveShortModalComponent,
    CreateLimitRequestModalComponent,
    ViewAuthenticateCallerModalComponent,
    RejectApprovePassModalComponent,
    viewPassApprovalModalComponent,
    VerifyUserComponent,
    MinDirective,
    MaxDirective,
    MasterDataToolTipComponent,
    AuditLogComponent,
    ViewPassAuditModalComponent,
    PassAuditComponent,
    PassTransactionComponent,
    ViewPassTransactionModalComponent,
    CreateTemplateComponent,
    UpdateTemplateComponent,
    ViewTemplateComponent,
    ViewTemplateCategoryComponent,
    TemplatesComponent,
    TemplateComponent,
    TemplateCategoryComponent,
    CreateTemplateCategoryComponent,
    UpdateTemplateCategoryComponent,
    OrgSecurityComponent,
    TeamComponent,
    CreateTeamComponent,
    UpdateTeamComponent,
    ViewTeamComponent,
    UserTeamComponent,
    AssignUpdateTeamModalComponent,
    StartdateConfigModalComponent,
    WidgetConfigModalComponent,
    ApprovalGroupComponent,
    OrgMasterDataComponent,
    MatChipAutoCompleteComponent,
    UserMatChipAutoCompleteComponent,
    CreateApprovalGroupComponent,
    UpdateApprovalGroupComponent,
    ViewApprovalGroupComponent,
    ApprovalRequestConfigurationComponent,
    CreateApprovalRequestConfigurationModalComponent,
    ViewApprovalRequestConfigurationModalComponent,
    UpdateApprovalRequestConfigurationModalComponent,
    ApprovalTypeComponent,
    ViewApprovalTypeModalComponent,
    UpdateApprovalTypeModalComponent,
    InviteNewUserModal,
    SiteSettingsComponent,
    RoleAssignmentApprovalComponent,
    ViewUserInvitationApprovalModal,
    OrgMasterDataComponent,
    ApproveRejectCellRendererComponent,
    PermissionsComponent,
    WebcamComponent,
    PassOnCreateApprovals,
    ApprovalStatusGridComponent,
    WIPSiteComponent,
    CreateOrganizationModalComponent,
    ApprovalMemberComponent,
    CreateApprovalMemberComponent,
    HolidayCalendarComponent,
    BasicSnackbarComponent,
    UserVerificationComponent,
    CustomSnackbarComponent,
    NotificationSettingsComponent,
    ButtonRendererComponent,
    AccessRequestComponent,
    CreateAccessRequestComponent,
    DeactivationDialogComponent,
    UserAccessRequestComponent,
    ViewAccessRequestModalComponent,
    SearchBarComponent,
    DomainComponent,
    CreateDomainComponent,
    EditDomainComponent,
    ViewDomainComponent,
    ViewPassOnCreateApprovalComponent,
    IntegrationComponent,
    ConfirmationPageComponent
    ],
  exports: [PhonenumberMaskDirective, SpecialCharacterDirective],
  imports: [
    MatButtonToggleModule,
    MatCardModule,
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatTabsModule,
    MatGridListModule,
    NgxChartsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ScrollingModule,
    AgGridModule.withComponents([]),
    MatMenuModule,
    BrowserAnimationsModule,
    IgxTimePickerModule,
    IgxInputGroupModule,
    IgxIconModule,
    NgMaterialMultilevelMenuModule,
    MatTooltipModule,
    GridsterModule,
    AvatarModule,
    NgxPrintModule,
    QRCodeModule,
    OktaAuthModule,
    CountdownModule,
    MatChipsModule,
    AgGridModule.withComponents([ApproveRejectCellRendererComponent]),
    WebcamModule,
    IgxButtonModule,
	  IgxCalendarModule,
	  IgxDialogModule,
    MatListModule,
    MatStepperModule
  ],

  providers: [
    ViewSelectorService,
    AuthService,
    DatePipe,
    { provide: OKTA_CONFIG, useValue: environment.oktaConfig },    
    LoggedInUserGuard,
    Title,
    UserRegisteredGuard,
    ViewControlGuard,
    MatDatePickerAdapter,
    {provide: ErrorHandler, useClass: ErrorHandlerService},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NewpassModalComponentDesktop,
    NewpassModalComponentMobile,
    ViewpassModalComponentDesktop,
    ViewpassModalComponentMobile,
    EditPassModalComponentDesktop,
    EditPassModalComponentMobile,
    DuplicatePassModalComponentMobile,
    DuplicatePassModalComponentDesktop,
    LoginRegistrationComponentMobile,
    DashBoardMobileComponent,
    ViewBuildingModalComponent,
    ViewEntryPointModalComponent,
    UpdateEntryPointComponent,
    SearchFilterModalComponent,
    CreateParkingModalComponent,
    ViewParkingModalComponent,
    UpdateParkingModalComponent,
    AssignUpdateRoleModalComponent,
    ViewParkingGroupModalComponent,
    CreateParkingGroupModalComponent,
    UpdateParkingGroupModalComponent,
    ViewOrganizationModalComponent,
    RoleMapUpdateModalComponent,
    RoleMapViewModalComponent,
    UpdateOrganizationModalComponent,
    ViewUserModalComponent,
    CreateUserModalComponent,
    WidgetSelectionModalComponent,
    CreateBadgeHolderPassModalComponent,
    RaiseCapacityModalComponent,
    ViewParkingLimitRequestModalComponent,
    ViewLimitApprovalModalComponent,
    RejectApproveShortModalComponent,
    CreateLimitRequestModalComponent,
    ViewPassAuditModalComponent,
    TemplatesComponent,
    WidgetConfigModalComponent,
    CreateApprovalRequestConfigurationModalComponent,
    ViewApprovalRequestConfigurationModalComponent,
    UpdateApprovalRequestConfigurationModalComponent,
    ViewApprovalTypeModalComponent,
    UpdateApprovalTypeModalComponent,
    InviteNewUserModal,
    MatChipAutoCompleteComponent,
    UserMatChipAutoCompleteComponent,
    ViewUserInvitationApprovalModal,
    CreateOrganizationModalComponent,
    CreateApprovalMemberComponent,
    ViewAccessRequestModalComponent
  ],
})
export class AppModule {}