import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { EntryPoint } from "../../../models/entry-point.model";
import { UserService } from "../../../services/user.service";
import { NotificationService } from "../../../services/notification.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { RoleMapService } from "../../../services/role-map.service";
import { HttpErrorResponse } from "@angular/common/http";
import { OrganizationService } from "../../../services/organization.service";
import { DialogService } from "../../../services/dialog.service";
import { AuthService } from "src/app/services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { SiteService } from "src/app/services/site.service";
import { NgForm } from '@angular/forms';
import { RequiredFieldsService } from 'src/app/services/required-fields.service';
import { SearchService } from "src/app/services/search.service";
import { MessageService } from "src/app/services/message.service";
import { PassService } from "src/app/services/pass.service";

@Component({
  selector: "app-view-authenticate-caller-modal",
  templateUrl: "./view-authenticate-caller-modal.component.html",
  styleUrls: ["./view-authenticate-caller-modal.component.css"],
})
export class ViewAuthenticateCallerModalComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;
  @ViewChild("authFormRef") authForm: NgForm;

  protected step = 0;
  protected entryPoints: Array<EntryPoint>;
  protected rolesLoaded: boolean = false;
  protected organizationsLoaded: boolean = false;
  protected authenticationCode: number;
  protected calledInUserorganizationID: number = 0;
  protected roleItems: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
  }> = [];
  protected roleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected originalRoleBasket: Array<{
    RoleID: number;
    RoleName: string;
    Active: number;
    UserRoleID: number;
  }> = [];

  protected organizationItems: Array<{
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected organizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];

  protected userName: string = "";

  protected originalOrganizationBasket: Array<{
    UserOrganizationID: number;
    OrganizationID: number;
    Name: string;
    Active: number;
  }> = [];
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected internalUser;
  protected externalUser;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      Active: number;
      CreatedBy: number;
      CreatedDate: string;
      Email: string;
      EmployeeRef: string;
      FirstName: string;
      LastName: string;
      MiddleName: string;
      ModifiedBy: number;
      ModifiedDate: string;
      OKTAID: string;
      Phone: string;
      Status: string;
      Suffix: string;
      UserID: number;
    },
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ViewAuthenticateCallerModalComponent>,
    private notificationService: NotificationService,
    protected organizationService: OrganizationService,
    private userService: UserService,
    private dialogService: DialogService,
    protected authService: AuthService,
    private siteService: SiteService,
    protected requiredFieldsService: RequiredFieldsService,
    protected searchService:SearchService,
    protected messageService: MessageService,
    protected passService: PassService
  ) {}

  ngOnInit(): void {
    // Load Organization Data
    this.userService
      .getUserAssociationAPI(this.data.UserID, this.siteService.selectedSiteID)
      .then(() => {
        // load the organization basket
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization
          .filter((f) => f.UserOrganizationActive);
       
        
        if(organizationBasket.length > 0){
          organizationBasket.forEach(element => {
            if(element.UserOrganizationActive){
              this.organizationBasket.push(element)
            }
          });
        }
        this.userService.callInUserOrganizations = organizationBasket;
        if(this.userService.callInUserOrganizations && this.userService.callInUserOrganizations.length == 1){
          this.userService.organizationID = this.userService.callInUserOrganizations[0].OrganizationID;
          this.calledInUserorganizationID = this.userService.organizationID;
          this.searchService.setOrganizationID(this.userService.organizationID);

        }
        this.organizationsLoaded = true;      
     
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
    
    this.userName = this.data.FirstName + " " + this.data.LastName;

    //labels by message service
    if (localStorage.getItem('ViewCallInPasslLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('ViewCallInPasslLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "View Caller Authentication Modal",
        "User"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('ViewCallInPasslLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onMainHeaderClose() {
    this.freezePanel.open();
  }
  onSaveChanges() {
    this.authService.userSiteListAuthorize(this.data.Email).subscribe((res: any) => {
      let authResponse = res.body[0];
       authResponse.AvailableSiteData.forEach(ele => {
        if (
          ele.SiteID === this.siteService.selectedSiteID &&
          ele.IsExternal == 1 &&
          ele.UserStatus == "Registered"
        ) this.externalUser = true;
      })
      authResponse.AvailableSiteData.forEach(ele => {
        if (
          ele.SiteID === this.siteService.selectedSiteID &&
          ele.IsExternal == 0 &&
          (ele.UserStatus == "Registered" || ele.UserStatus == "Expired")
        ) 
        this.internalUser = true;
      })
      if (this.externalUser) {
        this.authService.externalUserActiveBadge(this.data.UserID, this.siteService.selectedSiteID).subscribe((res: any) => {
          if (res.body[0].Active > 0) {
            this.authenticateUser();
          }
          else if (res.body[0].Active == 0) {
            this.onNoClick();
            this.dialogService.openAlertDialogDialog(
              this.screenLabels['AuthorizeCallerMsg'] ? this.screenLabels['AuthorizeCallerMsg'] : 'Authorize Caller',
              'Not a valid user to impersonate'
            );
          }
        });
      } 
      else if(this.internalUser){
        this.authenticateUser();
      }
      else {
        this.onNoClick();
        this.dialogService.openAlertDialogDialog(
          this.screenLabels['AuthorizeCallerMsg'] ? this.screenLabels['AuthorizeCallerMsg'] : 'Authorize Caller',
          'Not a valid user to impersonate'
        );
      }
    },
      err => {
        console.log("error", err);
      })
  }

  authenticateUser() {
    this.authService.authenticateCallerAPI(this.data.UserID, this.authenticationCode, this.data.Email).then(() => {
      if (this.authService.validCaller) {
        // this.userService.firstName = this.data.FirstName;
        // this.userService.middleName = this.data.MiddleName;
        // this.userService.lastName = this.data.LastName;
        // this.userService.suffix = this.data.Suffix;
        // this.userService.email = this.data.Email;

        this.userService.callInUserID = this.data.UserID;
        this.userService.loggedInUserID = this.userService.userID;
        this.userService.loggedInUserFirstName = this.data.FirstName;
        this.userService.loggedInUserMiddleName = this.data.MiddleName;
        this.userService.loggedInUserLastName = this.data.LastName;
        this.userService.loggedInEmail = this.data.Email;
        this.userService.loggedInPhone = this.data.Phone;
        this.userService.loggedInSuffix = this.data.Suffix;
        this.userService.loggedInUserFunctions = this.userService.userFunctions;
        this.userService.userFunctions = this.authService.userFunctions;
        this.userService.loggedInUserOrganizations = this.userService.userOrganizations;
        this.userService.loggedInUserOrganizationID = this.userService.organizationID;
        this.userService.organizationID = this.calledInUserorganizationID;
        this.userService.userOrganizations = this.userService.callInUserOrganizations;
        //set User details with callIn details
        this.userService.userID = this.userService.callInUserID; // set userID to call in userID
        this.passService.checkIsIgnoreLocksAPI(this.userService.userID, this.userService.organizationID, true);
        this.authService.userChangedToCallInUser.emit(true);
        const operateMessage = this.screenLabels['YouAreNowOperatingMsg'] ? this.screenLabels['YouAreNowOperatingMsg'] : 'You are now operating on behalf of '
        this.authService.callerMessage =
          operateMessage
          +
          this.data.FirstName +
          " " +
          this.data.LastName;
        this.onNoClick();
      } else {
        this.dialogService.openAlertDialogDialog(
          this.screenLabels['AuthorizeCallerMsg'] ? this.screenLabels['AuthorizeCallerMsg'] : 'Authorize Caller',
          this.screenLabels['AuthorizeCallerWarningMsg'] ? this.screenLabels['AuthorizeCallerWarningMsg'] : 'Please enter valid authentication code'
        );
      }
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  private loadOrganization() {
     this.organizationService
        .getOrganizationAPI()
        .then(() => {
          // load the organization basket
          this.prepareOrganizationList();
          
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
  }

  private prepareOrganizationList() {
    this.userService
      .getUserAssociationAPI(this.data.UserID,this.siteService.selectedSiteID)
      .then(() => {
        // load the organization basket
        const organizationBasket = this.userService
          .getUserDetails()[0]
          .UserOrganization.map((o) => ({
            UserOrganizationID: o.UserOrganizationID,
            Active: o.UserOrganizationActive,
            OrganizationID: o.OrganizationID,
            Name: this.organizationService
              .getOrganizations()
              .filter((f) => f.OrganizationID == o.OrganizationID)[0].Name,
            IsAdmin:o.IsAdmin
          }))
          .filter((f) => f.Active);
        this.organizationBasket = organizationBasket;
        this.userService.callInUserOrganizations = organizationBasket;
        this.organizationsLoaded = true
        const organizationItems = [];
        for (let j = 0; j < this.organizationItems.length; j++) {
          let matchFound = false;
          for (let i = 0; i < this.organizationBasket.length; i++) {
            if (
              this.organizationItems[j].OrganizationID ==
              this.organizationBasket[i].OrganizationID
            ) {
              matchFound = true;
            }
          }
          if (!matchFound) organizationItems.push(this.organizationItems[j]);
        }
        this.organizationItems = organizationItems;
        this.originalOrganizationBasket = [...this.organizationBasket];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.organizationItems = this.organizationService
      .getOrganizations()
      .map((o) => ({
        OrganizationID: o.OrganizationID,
        Name: o.Name,
        Active: o.Active,
      }))
      .filter((o) => o.Active === 1);
  }
  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Authenticate User',
      this.authForm
    );
  }

  onOrganizationChange(event){
    console.log(event);
  }
}
